import { Match, Switch } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Icon } from '@troon/icons';
import type { CurrencyFragment } from '../../../../graphql';
import type { ParentProps } from 'solid-js';

type Props = {
	discount: CurrencyFragment;
	promoCode?: string | null;
	subtotal: CurrencyFragment;
	tax: CurrencyFragment;
	total: CurrencyFragment;
};

export function Receipt(props: Props) {
	return (
		<div role="table">
			<div role="rowgroup" class="flex flex-col gap-4">
				<Row>
					<RowHead>Subtotal</RowHead>
					<Cell>{props.subtotal.displayValue}</Cell>
				</Row>
				<Switch>
					<Match when={props.promoCode && parseFloat(props.discount.value)}>
						<Row>
							<RowHead>Promo code ({props.promoCode})</RowHead>
							<Cell>
								<span class="inline-flex items-center gap-2 text-green-500">
									<Icon name="tag" />
									{props.discount.displayValue}
								</span>
							</Cell>
						</Row>
					</Match>
					<Match when={!props.promoCode && parseFloat(props.discount.value)}>
						<Row>
							<RowHead>Discount</RowHead>
							<Cell>
								<span class="inline-flex items-center gap-2 text-green-500">
									<Icon name="tag" />
									{props.discount.displayValue}
								</span>
							</Cell>
						</Row>
					</Match>
				</Switch>
				<Row>
					<RowHead>Tax</RowHead>
					<Cell>{props.tax.displayValue}</Cell>
				</Row>
				<Row class="font-semibold">
					<RowHead>Total</RowHead>
					<Cell>{props.total.displayValue}</Cell>
				</Row>
			</div>
		</div>
	);
}

function Row(props: ParentProps<{ class?: string }>) {
	return (
		<div role="row" class={twJoin('flex justify-between gap-6', props.class)}>
			{props.children}
		</div>
	);
}

function RowHead(props: ParentProps<{ class?: string }>) {
	return (
		<div class={twJoin('text-start', props.class)} role="rowheader">
			{props.children}
		</div>
	);
}

function Cell(props: ParentProps) {
	return (
		<div role="cell" class="text-end">
			{props.children}
		</div>
	);
}
